import $ from 'jquery';
import tmpl from 'blueimp-tmpl';
import List from 'list.js';

function busca(empresaId) {
  const apiUrl = 'https://app.benjimed.com.br/api/transparencia/';
  // const apiUrl = 'http://localhost:8080/api/transparencia/';
  let empresa = null;
  let unidades = [];
  let itens = [];
  let intervalId = null;

  function buscaEmpresas() {
    $.ajax(`${apiUrl}empresas-farmacia`)
      .then(result => {
        $('#empresa').html(tmpl('tmpl-empresas', { empresas: result.data }));
      })
      .catch((error) => {
        console.log(error);
        alert('Falha de comunicação.');
      });
  }

  function buscaUnidadesFarmacia(empresa) {
    const $dados = $('#unidades');

    if (empresa == '') {
      $dados.html('');
      return false;
    }

    if (!intervalId) {
      $dados.html('<p>carregando...</p>');
    }

    if (parseInt(empresa) > 0) {
      $.ajax(`${apiUrl}unidades-farmacia?empresa=${empresa}`)
        .then((result) => {
          unidades = result.data;
          $dados.html(tmpl('tmpl-unidades', { unidades }));
          // $.each(unidades, (i) => {
          //   buscaDados(i);
          // });
        })
        .catch((error) => {
          console.log(error);
          alert('Falha de comunicação.');
        });
    }
  }

  function buscaItensFarmacia(empresa, unidade) {
    const $dados = $('#dados');

    if (empresa == '') {
      $dados.html('');
      return false;
    }

    if (!intervalId) {
      $dados.html('<p>carregando...</p>');
    }

    if (parseInt(empresa) > 0) {
      const params = {empresa};

      if (unidade) {
        params['unidade'] = unidade;
      }

      $.ajax(`${apiUrl}itens-farmacia?${ $.param(params) }`)
        .then(result => {
          itens = result.data;
          if (itens.length > 0) {
            $dados.html(tmpl('tmpl-itens-farmacia', { itens, empresa, 'unidade': unidade }));

            new List('dados', {valueNames: [ 'title' ]});
          }
        })
        .catch((error) => {
          console.log(error);
          alert('Falha de comunicação.');
        });
    }
  }

  function buscaEstoqueFarmacia(empresa, farmaco, unidade) {
    const $dados = $(`#farmaco-${farmaco}`);

    $dados.before(`<p id="farmaco-${farmaco}-loader">carregando...</p>`);

    if (parseInt(empresa) > 0 && parseInt(farmaco) > 0) {
      $.ajax(`${apiUrl}estoque-farmacia?empresa=${empresa}&farmaco_id=${farmaco}&unidade=${unidade}`)
        .then((result) => {
          $(`#farmaco-${farmaco}-loader`).remove();

          $dados.html(tmpl('tmpl-estoque-farmacia', result));
        })
        .catch((error) => {
          console.log(error);
          alert('Falha de comunicação.');
        });
    }
  }

  if (empresaId !== null) {
    $('#empresa').trigger('change');
    $('#filtro').hide();
    buscaUnidadesFarmacia(empresaId);
    buscaItensFarmacia(empresaId, null);
  } else {
    buscaEmpresas();
  }

  $(document).on('change', '#empresa', function (e) {
    const $this = $(this);

    buscaUnidadesFarmacia($this.val());
    buscaItensFarmacia($this.val(), null);

    empresaId = $this.val();
    empresa = $this.val();

    if (intervalId !== false) {
      clearTimeout(intervalId);
      intervalId = false;
    }

    if (empresa === '') {
      empresa = null;
      unidades = [];
      return false;
    }

    intervalId = setInterval(() => {
      buscaItensFarmacia($this.val());
    }, 300000); //5min
  });

  $(document).on('change', '#unidade', function (e) {
    const $this = $(this);

    if (empresaId === '' || empresaId == null || isNaN(parseInt(empresaId))) {
      empresaId = $('#empresa').val();
      console.log('>>> ', $('#empresa').val());
    }

    buscaItensFarmacia(empresaId, $this.val());

    if (intervalId !== false) {
      clearTimeout(intervalId);
      intervalId = false;
    }

    if (empresaId === '') {
      empresa = null;
      return false;
    }

    intervalId = setInterval(() => {
      buscaItensFarmacia(empresaId, $this.val());
    }, 300000); //5min
  });

  $(document).on('click', 'a.load-estoque', function (e) {
    const $this = $(this);
    buscaEstoqueFarmacia($this.data('empresa'), $this.data('farmaco'), $this.data('unidade'));
    empresaId = $this.data('empresa');

    if (intervalId !== false) {
      clearTimeout(intervalId);
      intervalId = false;
    }

    if (empresaId === '') {
      empresa = null;
      return false;
    }

    e.preventDefault();
  });
}

export default busca;
