export default function getParameterByName(name, url) {
  // eslint-disable-next-line no-param-reassign
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[[\]]/g, '\\$&');
  // eslint-disable-next-line one-var
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
    results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
